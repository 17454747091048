import React from 'react'

import config from 'constants/config'
import { Link } from 'tools/Links'

function Footer(props) {
  return (
    <div className="base-navbar bt b--navbar" id="footer">
      <div className="flex-center flex-column mt5 pa2 b mb4">
        <div className="flex-items items-start flex-column flex-row-s justify-around-s w-100 w-50-l f2">
          <div className="mb1">
            <Link to="/legal/" logOrigin="FooterTerms">
              Terms of Use
            </Link>
          </div>
          <div className="mb1">
            <Link to="/legal/" logOrigin="FooterPrivacy">
              Privacy Policy
            </Link>
          </div>
          <div className="f2">
            <Link to="/legal/" logOrigin="FooterCopyright" className="flex-items">
              <img
                src={config.logo.small}
                alt={config.name}
                style={{ height: '1rem' }}
                className="mr2 logo"
              />
              {config.name} &copy; {new Date().getFullYear()}, &trade; Protos, LLC
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}


export default Footer
