import React from 'react'
import { createRoot } from 'react-dom/client'

import config from 'constants/config'

import { SignonProvider } from 'components/Signon/Provider'
import { GlobalProvider } from 'reducer/global'

function cleanUrl(key) {
  const str = config[key]
  return new URL(str).toString().replace(/\/+$/, '')
}

export default async function startup(conf, Router, props) {
  Object.assign(config, conf)
  config.pub = cleanUrl('pub')
  config.app = cleanUrl('app')
  config.web = cleanUrl('web')
  Object.freeze(config)
  console.log(
    `BUILD?=${config.build} API=${config.app} PUB=${config.pub} WEB=${config.web}`
  )

  const container = document.getElementById('root')
  const root = createRoot(container)

  // only include high level providers and configuration things here
  root.render(
    <GlobalProvider>
      <SignonProvider>
        <Router {...props} />
      </SignonProvider>
    </GlobalProvider>
  )
}
