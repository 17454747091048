import React, { useCallback, useContext, useEffect } from 'react'

import notify from 'tools/Notify'

import GlobalContext from 'reducer/global'

import style from './index.module.scss'
import { EXPIRE_TIME } from './reducer'

export function Notify() {
  const [
    {
      notify: { expireFlag, popups }
    },
    dispatch
  ] = useContext(GlobalContext)

  useEffect(() => {
    // DONT CLEAR TIMEOUT IN CLEANUP
    // https://react.dev/reference/react/useEffect#my-cleanup-logic-runs-even-though-my-component-didnt-unmount
    setTimeout(() => {
      notify.expire(dispatch)
    }, EXPIRE_TIME)
  }, [expireFlag, dispatch])

  const hide = useCallback((id) => notify.remove(dispatch, id), [dispatch])

  if (!popups.length) return null

  return (
    <div className="flex flex-column fixed bottom-0 overflow-hidden z-999 gv3 ma3 ma4-m ma5-l f3">
      {popups.map((msg) => (
        <div
          key={msg.id}
          className={`relative br2 w-100 b--solid bw2 ${style.msg} pa3 pa4-m lh-copy base-${msg.type} popup`}
          onClick={() => {
            notify.noexpire(dispatch, msg.id)
            //if (msg.onClick) {
            //  msg.onClick(dispatch)
            //}
          }}
        >
          <button
            className="absolute top-0 right-0 clear pa2"
            onClick={(e) => {
              e.stopPropagation()
              hide(msg.id)
            }}
          >
            <i className="fas fa-times" />
          </button>
          <div>{msg.content}</div>
        </div>
      ))}
    </div>
  )
}

export default Notify
