import { R_GLOBAL } from 'reducer/global'
import { Link } from 'tools/Links'
import paths from '../../paths'
import config from 'constants/config'

export function HomeLink({ showName }) {
  return (
    <Link to={paths.home} className="hover-hilite pa2 br1 flex-items b ">
      <img
        src={config.logo.small}
        alt={config.name}
        style={{ height: '2rem' }}
        className={`logo db ${showName ? 'mr2' : ''}`}
      />
      {showName && 'REVENANT'}
    </Link>
  )
}

export function initMenu(dispatch) {
  const siteMenu = [
      {
        to: 'https://revenant.studio/studio/',
        label: 'Studio',
        icon: 'user-pen',
        type: "ExtLink",
        target: '_self'
      },
      {
        to: 'https://revenant.studio/books/',
        label: 'Books',
        icon: 'book-open',
        type: "ExtLink",
        target: '_self'
      },
      {
        to: 'https://revenant.studio/social/',
        label: 'Social',
        icon: 'comments',
        type: "ExtLink",
        target: '_self'
      }
  ]

  const userMenu = [
    {
      to: paths.preferences,
      label: 'Preferences'
    },
    {
      divider: true,
      id: 'admin-div',
      authz: 'admin_user'
    },
    {
      to: paths.doc.pageView,
      label: 'Docs',
      icon: 'file',
      authz: 'doc_admin'
    },
    {
      to: paths.doc.tableView,
      label: 'Tables',
      icon: 'table',
      authz: 'doc_admin'
    },
    {
      to: paths.admin.file,
      label: 'Files',
      icon: 'file',
      authz: 'file_admin'
    },
    {
      to: paths.admin.summary,
      label: 'Admin',
      // todo: flip to mentor action
      authz: 'admin_user'
    },
    // {
    //   to: paths.story,
    //   label: 'Stories',
    //   icon: 'lightbulb',
    //   authz: 'admin_user'
    // },

    {
      divider: true,
      id: 'signout-div'
    },
    {
      to: paths.signout,
      label: 'Sign Out'
    }
  ]

  dispatch({ type: R_GLOBAL.PAGE_MENU, siteMenu, userMenu })
}
