import React, { useCallback, useMemo } from 'react'
import { MenuItem } from 'react-menu-list'
import {
  Link as InnerLink,
  NavLink as InnerNavLink,
  useHistory
} from 'react-router-dom'

export function Link({
  type = 'Link',
  // ignored for now
  logOrigin = undefined,
  to = undefined,
  onClick: innerOnClick = undefined,
  children = undefined,
  ...rest
}) {
  const history = useHistory()

  let onClick = useCallback(
    (ev) => {
      if (type === 'Link') {
        if (innerOnClick) {
          innerOnClick(ev, to)
        } else if (!to) {
          console.log("Invalid link data, missing 'to'")
        }
        return undefined
      } else {
        if (innerOnClick || to) {
          to && history.push(to)
          innerOnClick && innerOnClick(ev, to)
        } else {
          console.log("Invalid link data, missing 'onClick' and 'to'")
        }
      }
    },
    [innerOnClick, to, type, history]
  )

  // hrm; because callbacks have to be first... has to be a better way, this is
  // still clunky -BJG
  if (!innerOnClick && type === 'Link') {
    onClick = undefined
  }

  switch (type) {
    case 'disable':
      return <div {...rest} />
    case 'InnerNavLink':
      return (
        <InnerNavLink {...rest} to={to} onClick={onClick} children={children} />
      )
    case 'ExtLink':
      return <ExtLink to={to} {...rest} children={children} />

    case 'Link':
      if (!to) {
        throw new Error(`<Link> has bad args`, { to, onClick })
      }

      return (
        <InnerLink {...rest} to={to} onClick={onClick}>
          {children}
        </InnerLink>
      )

    default:
      if (type.length > 0 && type === type.toLowerCase()) {
        return React.createElement(type, { ...rest, onClick }, children)
      } else {
        throw new Error(`Invalid type for <Link>`, { type })
      }
  }
}

export function NavLink({
  className = 'navlink',
  icon,
  label,
  children,
  type = "InnerNavLink",
  ...rest
}) {
  return (
    <Link
      activeClassName="active"
      className={className}
      type={type}
      {...rest}
    >
      {icon}
      {label || children}
    </Link>
  )
}
// const history = useHistory()
// const [state, dispatch] = useContext(GlobalContext)
// let {
//   disable,
//   icon,
//   className = '',
//   innerClass = 'flex-center nowrap',
//   label,
//   children,
//   ...rest
// } = props
//
// const args = linkTo({
//   props: rest,
//   to: rest.to,
//   onClick: rest.onClick,
//   state,
//   dispatch,
//   history
// })

//
//   return (
//     <InnerNavLink activeClassName="active" className={className} {...args}>
//     </InnerNavLink>
//   )
// }

// react-menu-list
export function MenuLink({ className = 'ph3 pv2 hover-hilite', ...other }) {
  return (
    <MenuItem className={className}>
      <NavLink {...other} />
    </MenuItem>
  )
}

export function ExtLink({
  to,
  children = undefined,
  target="_blank",
  className = '',
  label = ''
}) {
  children = useMemo(() => {
    if (!children) {
      try {
        const url = new URL(to)
        return `${label ? label : ''}${url.hostname}`
      } catch (err) {
        console.log({ err, to })
      }
    } else {
      return children
    }
  }, [label, to, children])
  return (
    <a href={to} className={className} rel="noopener noreferrer" target={target}>
      {children}
    </a>
  )
}
//
// export function DownloadLink({
//   filename,
//   data,
//   ext,
//   children,
//   className = undefined
// }) {
//   const [url, setUrl] = useState('')
//   const updateState = useCallback(() => {
//     const url = getExportFileUrl(stringify(data))
//     setUrl(url)
//   }, [data])
//
//   return (
//     <a
//       href={url}
//       download={filename + '.' + ext}
//       target="_blank"
//       rel="noopener noreferrer"
//       className={`${className} ${url ? 'pointer' : 'cursor-wait'}`}
//       onMouseEnter={updateState}
//     >
//       {children}
//     </a>
//   )
// }

export default Link
