import React, { createContext, useReducer } from 'react'

import { R_NOTIFY } from 'tools/Notify/reducer'
import { R_SIGNON } from 'utils/signon'

// import { strcmp } from 'utils/string'
import {
  BASE_R_GLOBAL,
  baseGlobalReducer,
  generateBaseGlobalState,
  resetBaseGlobalState
} from 'reducer/global/base'

function generateSiteState() {
  return {}
}

const defaultState = generateBaseGlobalState(generateSiteState())

function resetGlobalState(oldState, seeds) {
  return resetBaseGlobalState(oldState, seeds || generateSiteState())
}

const R_GLOBAL = Object.freeze({
  ...BASE_R_GLOBAL
  // start local addons after 10k+
})

function reducer(state, args) {
  switch (args.type) {
    default:
      return baseGlobalReducer(state, args)
  }
}

const GlobalContext = createContext(null)
function GlobalProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, defaultState)
  return (
    <GlobalContext.Provider value={[state, dispatch]}>
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalContext
export {
  R_GLOBAL,
  R_SIGNON,
  R_NOTIFY,
  GlobalProvider,
  resetGlobalState,
  GlobalContext
}
