import React, { useContext, useState } from 'react'

import GlobalContext from 'reducer/global'

import { doSignOnLocal } from './doSignOnLocal'
import { REQUIREMENTS, badPassword } from './password'

////////////////////////////////////////////////////////////////////////////////
function checkPassword(pass1, pass2, signup, setStatus) {
  pass1 = pass1.trim()
  if (!signup) {
    return pass1
  }
  const error = badPassword(pass1, pass2)
  setStatus(error)
  return pass1
}

export function SignOnLocal({ signup, label, status, setStatus }) {
  const [state, dispatch] = useContext(GlobalContext)
  const [password, setPass1] = useState('')
  const [pass2, setPass2] = useState('')
  const [handle, setHandle] = useState('')

  return (
    <>
      <input
        className="w-100 br2 db"
        placeholder="Your email"
        value={handle}
        onChange={(e) => setHandle(e.target.value.trim())}
      />
      {signup && <div className="f2">{REQUIREMENTS}</div>}
      <input
        className="w-100 br2 db"
        type="password"
        placeholder={'Your ' + (signup ? 'desired ' : '') + 'password'}
        value={password}
        onChange={(e) =>
          setPass1(checkPassword(e.target.value, pass2, signup, setStatus))
        }
      />
      {signup ? (
        <input
          className="w-100 br2"
          type="password"
          placeholder={'Your desired password (repeated)'}
          value={pass2}
          onChange={(e) =>
            setPass2(checkPassword(e.target.value, password, signup, setStatus))
          }
        />
      ) : null}

      <div className="flex justify-around items-center">
        <button
          className="large"
          style={{ border: 0, margin: 0 }}
          onClick={(e) => {
            e.preventDefault()
            setStatus(undefined)
            doSignOnLocal({
              state,
              signup,
              status: { status: status ?? '', setStatus },
              handle,
              password,
              dispatch
            })
          }}
        >
          {label}
        </button>
      </div>
    </>
  )
}

export default SignOnLocal
