// import React from 'react'
import startup from 'startup'

import Root from 'app/codex/Root'
import Router from 'app/codex/Root/Router'

// import SiteLogo from 'app/codex/SiteLogo'

startup(
  {
    name: 'Revenant Creative Studio',
    nameAn: 'a Revenant Creative Studio',
    nameLegal: 'Protos, LLC',
    nameShort: 'Revenant',
    logo: {
      small: '/assets/codex-logo-512.webp',
      large: '/assets/codex-logo-512.webp'
    }
  },
  Router,
  { Root }
)
