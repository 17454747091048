import React from 'react'

import { v4 as uuid } from 'uuid'

export const R_NOTIFY = {
  PUSH: 1,
  EXPIRE: 2,
  NOEXPIRE: 3,
  REMOVE: 4,
  EDITING: 5,
  SAVED: 6
}

// correlates to styles matching the name
export const NOTIFY_LEVELS = {
  INFO: 'info',
  WARN: 'warn',
  ERROR: 'error'
}
// more readable code externally

export const EXPIRE_TIME = 4000 // 5_000
export const defaultMessage = {
  id: undefined,
  content: undefined,
  expires: true,
  level: NOTIFY_LEVELS.WARN,
  posted: undefined
}

export const defaultState = {
  popups: [],
  navbar: undefined,
  // note: considered making this a keyword dict for each column/field being
  // edited, but you can only have one focus at a time so that isn't necessary
  editing: false,
  expireFlag: 0
}

function newMessage(value) {
  return { ...defaultMessage, id: uuid(), posted: Date.now(), ...value }
}

export function reducer(s, { type, value }) {
  // all notification actions should prompt an expiration check except EXPIRE
  const state =
    type === R_NOTIFY.EXPIRE ? s : { ...s, expireFlag: s.expireFlag + 1 }

  switch (type) {
    case R_NOTIFY.EDITING: {
      if (state.editing) {
        return state
      }
      // add a future posted date so it expires later than a few seconds
      return {
        ...state,
        navbar: newMessage({
          content: <span className="throb">Editing</span>
          // posted: Date.now() + 30000
        })
      }
    }

    case R_NOTIFY.SAVED:
      return {
        ...state,
        editing: false,
        navbar: value?.success && newMessage({ content: 'Saved' })
      }

    case R_NOTIFY.PUSH: {
      const msg = newMessage(value)

      // info goes to navbar, unless something is already there!
      if (msg.level === NOTIFY_LEVELS.INFO && msg.expires && !state.navbar) {
        return { ...state, navbar: msg }
      }

      return { ...state, popups: state.popups.concat(msg) }
    }

    case R_NOTIFY.NOEXPIRE: {
      const popups = state.popups.map((msg) => {
        if (msg.id === value) {
          return { ...msg, expires: false }
        }
        return msg
      })
      return { ...state, popups }
    }

    case R_NOTIFY.EXPIRE: {
      if (state.expireFlag === 0) return state
      //console.log(state.popups.map(({posted}) => posted))
      // take off an extra 500ms so we don't have a race condition
      const older = Date.now() - EXPIRE_TIME + 500
      const popups = state.popups.filter(
        (msg) => !msg.expires || msg.posted > older
      )
      const navbar = state?.navbar?.posted > older ? state.navbar : undefined
      return { ...state, popups, navbar }
    }

    case R_NOTIFY.REMOVE: {
      const popups = state.popups.filter((msg) => msg.id !== value)
      const navbar = state?.navbar?.id !== value ? state.navbar : undefined
      return { ...state, popups, navbar }
    }

    default:
      throw new Error(`Notify reducer: no such type: ${type}!`)
  }
}
