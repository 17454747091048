import React, { useContext, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { R_SIGNON } from 'utils/signon'

import GlobalContext from 'reducer/global'
import { usePageTitle } from 'reducer/global/hooks'

import SignForm from './SignForm'

// BUG; global shouldn't use site-paths like this
import paths from 'constants/paths'

export function Signout() {
  const [, dispatch] = useContext(GlobalContext)
  const history = useHistory()

  useEffect(() => {
    dispatch({ type: R_SIGNON.SIGN_OUT })
    history.replace('/signon')
  }, [dispatch, history])

  return null
}

export function Signon() {
  usePageTitle('Sign On')

  return <SignonInline />
}

export function SignonInline() {
  const history = useHistory()
  const [{ user }] = useContext(GlobalContext)
  const { up } = useParams()

  useEffect(() => {
    if (user.isAuthed) {
      if (user.age < 1 && paths.firstTime) {
        history.push(paths.firstTime)
      } else {
        history.push('/')
      }
    }
  }, [history, user.isAuthed, user.age])

  return <SignForm signup={!!up} />
}

export default Signon
