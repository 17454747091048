import React, { useMemo } from 'react'
import { MenuButton, MenuItem, MenuList } from 'react-menu-list'

// import config from 'constants/config'
import { NavLink } from 'tools/Links'

function NavMenuItem({
  item: {
    label,
    id = undefined,
    to = undefined,
    external = undefined,
    divider = false,
    miniLabel = false,
    Linker = NavLink,
    authz
  },
  user
}) {
  if (miniLabel) {
    return (
      <div key={id} className="f1 ml3 ttu gray" style={{ marginTop: '-0.4rem' }}>
        {authz && <i className="mr2 f1 fas fa-key" />}
        {miniLabel}
      </div>
    )
  } else if (divider) {
    return <div key={id} className="bt b--navbar" />
  } else {
    return (
      <Linker to={to} className="menuitem navlink">
        <MenuItem>
          <div className="relative f3 ph2">{label}</div>
        </MenuItem>
      </Linker>
    )
  }
}

export function NavMenu({ menu, className, children, user }) {
  const filteredMenu = useMemo(() => {
    return menu.filter((item) => !item.authz || (user && user.can(item.authz)))
  }, [menu, user])
  return (
    <MenuButton
      className={className}
      menu={
        <MenuList>
          <div className="theme-dark">
            <div className="navmenu flex flex-column">
              {filteredMenu.map((item) => (
                <NavMenuItem item={item} user={user} key={item.id || item.to} />
              ))}
            </div>
          </div>
        </MenuList>
      }
    >
      {children}
    </MenuButton>
  )
}
