import React, { useContext } from 'react'

import SignForm from 'components/Signon/SignForm'
import GlobalContext from 'reducer/global'

function IdentifiedUser({ children, className = '', explain = undefined }) {
  const [{ user }] = useContext(GlobalContext)

  if (user.isIdentified) {
    return children
  }
  return <UnidentifiedUser>{explain}</UnidentifiedUser>
}

function UnidentifiedUser({ children }) {
  return (
    <SignForm signup={true}>
      <div className="flex flex-column items-center gv4 mb5">
        <h1>Welcome!</h1>
        {children && <div className="f4 tc">{children}</div>}
        <div className="f4 tc i b fg-hilite">
          To use this feature, we need you to sign in first. Thanks!
        </div>
      </div>
    </SignForm>
  )
}

export default IdentifiedUser
