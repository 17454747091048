import React from 'react'

import NotifyPopup from 'tools/Notify/Popup'

import { useSignon } from 'reducer/global/hooks'

import Footer from './Footer'
import NavBar from './NavBar'

function Root({ children }) {
  // useUpdateQuerys(GlobalContext, R_GLOBAL.UPDATE_QUERY)
  useSignon()

  // useEffect(() => {
  //   dispatch({ type: R_GLOBAL.PAGE_MAIN, value: { menu, bar } })
  // }, [dispatch])

  return (
    <>
      <div className="base-body" id="body">
        {children}
        <NotifyPopup />
      </div>
      <Footer />
      <NavBar />
    </>
  )
}

export default Root
