import React, { useContext, useEffect } from 'react'


import { NavLink } from 'tools/Links'
import Activity from 'tools/NavTabs/Activity'
import { SectionNav } from 'tools/Navigator'

import GlobalContext from 'reducer/global'

import { NavMenu } from './NavMenu'

import { initMenu, HomeLink } from './config'

const COMMON = 'pa1 br1'
export function NavBar() {
  const [{ menus, title, user }, dispatch] = useContext(GlobalContext)

  useEffect(() => {
    if (!menus) {
      initMenu(dispatch)
    }
  }, [dispatch, menus])

  if (!menus) {
    return null
  }

  return (
    <div className="base-navbar flex-between ph3 gh3 fixed top-0 left-0 right-0 navbar-height bb b--navbar">
      <HomeLink showName={!title} />
      <SectionNav />
      <SiteMenu menu={menus.siteMenu} />
      <UserMenu menu={menus.userMenu} user={user} />
    </div>
  )
}

export function UserMenu({ menu, user, className = COMMON + ' clear' }) {
  const url = ''

  const hasActivity = false

  if (user.isIdentified && user.can) {
    return (
      <NavMenu menu={menu} className={className} user={user}>
        <div className="menuitems flex-center flex-column fw3 f2">
          {url ? (
            <div className="navPic">
              <img src={url} alt="" />
            </div>
          ) : (
            <i className="f4 mb1 fas fa-bars" />
          )}
          <div className="flex-items">
            Me <i className="ml2 fas fa-chevron-down" />
          </div>
          <Activity show={hasActivity} />
        </div>
      </NavMenu>
    )
  }
  return (
    <NavLink
      to="/signon"
      className={className}
      logOrigin="SignOn"
      icon={
        <div className="flex flex-column items-center fw3 f2">
          <i className="fas fa-sign-in-alt mb1 f4" />
          Sign In
        </div>
      }
    />
  )
}

function SiteMenu({ menu, className = COMMON }) {
  return (
    <>
      {menu.map((i, x) => (
        <NavLink
          key={i.label}
          to={i.to}
          label={
            <div className="flex flex-column items-center fw3 f2 relative">
              <i className={`fas fa-${i.icon} mb1 f4`} />
              <Activity show={i.activity} />
              {i.label}
            </div>
          }
          target={i.target}
          type={i.type}
          className={className}
        ></NavLink>
      ))}
    </>
  )
}

export default NavBar
