import React from 'react'

// import { ExtLink } from 'tools/Links'

export const TERMS = (
  <>
    to our <a href="/legal/">Privacy and Use Policy</a>.
  </>
)

export const REQUIREMENTS = (
  <>
    Password should be at least 8 characters long and have a mix of lower,
    uppercase, and special characters (non alphanumeric).
  </>
)

/*
 * @doctests
 *
 * ```js
 * t.is(validatePassword('cat', 'dog'), 'Passwords do not match')
 * t.is(validatePassword('mouse'), 'Password is not long enough')
 * t.is(validatePassword('123456789'), 'Password needs letters')
 * t.is(validatePassword('horsebarnbatter'), 'Password needs upper and lower case characters')
 * t.is(validatePassword('BigLittle'), 'Password needs numbers')
 * t.is(validatePassword('HouseFarm99'), 'Password needs special characters')
 * t.is(validatePassword('#TinkerTailorSoldierSpy2011'), undefined)
 * ```
 */

export function badPassword(word, compare = undefined) {
  if (compare !== undefined && word !== compare) {
    return 'Passwords do not match'
  }
  if (word.length < 8) {
    return 'Password is not long enough'
  }
  if (word.toLowerCase() === word) {
    return 'Password needs upper and lower case characters'
  }
  if (word.replace(/[^a-z0-9]/i, '') === word) {
    return 'Password needs special characters'
  }
  if (word.replace(/[0-9]/, '') === word) {
    return 'Password needs numbers'
  }
  if (word.replace(/[a-z]/i, '') === word) {
    return 'Password needs letters'
  }
  return undefined
}

export function badCode(code, isAuthed) {
  if (code <= 0) {
    if (isAuthed) {
      return 'Missing a reset code / previous password'
    }
    return 'Missing a reset code'
  }
  return undefined
}
