import { useContext, useEffect } from 'react'

import { useQuery } from '@apollo/client'

// import { useLocation } from 'react-router-dom'
import notifier from 'tools/Notify'
import { R_SIGNON, getAccessToken } from 'utils/signon'

import { LOAD_USER } from 'components/User/graphql'
import GlobalContext, { R_GLOBAL } from 'reducer/global'

export function usePageTitle(title) {
  const [, dispatch] = useContext(GlobalContext)
  useEffect(() => {
    dispatch({ type: R_GLOBAL.PAGE_TITLE, title })
  }, [dispatch, title])
}

export function windowTheme() {
  return window.matchMedia('(prefers-color-scheme: dark)').matches
    ? 'dark'
    : 'light'
}

export function pageTheme() {
  return isThemeDark() ? 'dark' : 'light'
}

export function isThemeDark() {
  return document.getElementById('theme').classList.contains('theme-dark')
}

function asTheme(str) {
  if (str[0] !== 't') {
    return 'theme-' + str
  }
  return str
}

export function useTheme() {
  const [state, dispatch] = useContext(GlobalContext)

  useEffect(() => {
    if (window.matchMedia) {
      if (window.matchMedia('(prefers-color-scheme: light)').matches) {
        dispatch({ type: R_GLOBAL.PAGE_THEME, value: 'theme-light' })
      }
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .addEventListener('change', (e) => {
          dispatch({
            type: R_GLOBAL.PAGE_THEME,
            value: asTheme(e.matches ? 'dark' : 'light')
          })
        })
    }
  }, [dispatch])

  const theme = state.user.settings.theme || state.pageTheme || windowTheme()

  useEffect(() => {
    document.getElementById('theme').classList.remove('theme-dark')
    document.getElementById('theme').classList.remove('theme-light')
    document.getElementById('theme').classList.add(asTheme(theme))
  }, [theme])
}

////////////////////////////////////////////////////////////////////////////////
export function useSignon() {
  const [, notifyDispatch] = useContext(notifier.Context)
  const [state, dispatch] = useContext(GlobalContext)

  // initialize apollo
  useEffect(() => {
    dispatch({ type: R_GLOBAL.APOLLO_RESET, dispatch })
  }, [dispatch])

  // Load user, refetch when we are able to do so (have a validation token)
  const { refresh, error: signon_error } = state.signon
  useEffect(() => {
    if (refresh) {
      getAccessToken(dispatch)
    }
  }, [refresh, dispatch])

  useQuery(LOAD_USER, {
    variables: { id: state.signon.sub },
    skip: !state.signon.sub,
    fetchPolicy: 'network-only',
    onCompleted({ users }) {
      if (users.success) {
        dispatch({ type: R_GLOBAL.USER_SET, value: users.result })
      } else {
        notifier.error(dispatch, users.reason)
      }
    }
  })

  // convert signon errors to notify
  useEffect(() => {
    if (signon_error) {
      notifier.error(notifyDispatch, signon_error)
      dispatch({ type: R_SIGNON.ERROR_CLEAR })
    }
  }, [signon_error, dispatch, notifyDispatch])
}
