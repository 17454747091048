/*

meant to be used alongside existing contexts; will insert into reducer state:

  Context/state: {
    nav: {
      itemId,
      current,
      spec: {} - result of makeNav()
    },
    title: '',
    ...
  }

  see notes inside hooks.js for args on path() callbacks

  to use:

  1. define a global NAVSPEC at the module level so its static with makeNav(). Note:
     if doing section nav, it must use GlobalContext
     if doing sub nav, it must use a different Context — this is by design
  2. initialize this with one of:

     useSectionNav(NAVSPEC)
     useSubNav(NAVSPEC)

  3. Where you wish nav elements to appear use one of:

    <SectionNav spec={NAVSPEC} />
    <SubNav spec={NAVSPEC} />

  4. Where you wish the page elements to appear use:

    <PageRouter spec={NAVSPEC} />

*/
import React, { Suspense, useContext, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { NavLink } from 'tools/Links'
import { LoadingInline } from 'tools/Loading'
import { MaxViewPage } from 'tools/Uniform'

import GlobalContext from 'reducer/global'

////////////////////////////////////////////////////////////////////////////////
export function PageRouter({ spec }) {
  const { Context, routeMap, isSub } = spec
  const [c] = useContext(Context)
  const { nav } = c

  const { Component } = routeMap[nav?.current] || {}
  if (Component) {
    return (
      <Suspense fallback={<LoadingInline />}>
        <Component tab={nav.current} />
      </Suspense>
    )
  }

  // console.log("Page Router Fail", {nav, c, spec})
  if (nav) {
    let route = undefined
    if (spec.redirect) {
      route = routeMap[spec.redirect(nav)]
    } else if (spec.orig) {
      route = routeMap[spec.orig]
    }
    if (route) {
      if (isSub) {
        return <RedirectSub route={route} spec={spec} />
      } else {
        return <RedirectSection route={route} />
      }
    }
  }
  return null
}

function RedirectSub({ route, spec }) {
  const [global] = useContext(GlobalContext)
  const [state] = useContext(spec.Context)

  const to = route.path({
    section: global.nav,
    sub: state.nav,
    spec,
    tab: route.id,
    state,
    global
  })

  const winLoc = window.location.pathname

  // this is a hack because when we change our path, the states haven't updated
  // yet, so we need to drop out of sub-nav redirects for a moment if our base
  // paths don't match
  if (to.substring(0, winLoc.length) !== winLoc) {
    console.log('Not redirecting', to.substring(0, winLoc.length), winLoc)
    return
  }
  return <RedirectNav to={to} />
}

function RedirectSection({ route }) {
  const [global] = useContext(GlobalContext)

  if (!global?.nav) return

  const to = route.path({
    section: global.nav,
    spec: global.nav.spec,
    tab: route.id,
    state: global,
    global
  })

  return <RedirectNav to={to} />
}

function RedirectNav({ to }) {
  const history = useHistory()

  const cur = history.location.pathname.substring(0, to.length)

  // wait 1 second before redirecting; this way we don't needlessly redirect
  useEffect(() => {
    let isMounted = true

    setTimeout(() => {
      if (isMounted && cur !== to) {
        history.replace(to)
      }
    }, 1000)

    return () => {
      isMounted = false
    }
  }, [history, cur, to])

  return null
}

////////////////////////////////////////////////////////////////////////////////
export function SectionNav() {
  const [global] = useContext(GlobalContext)
  const { nav, title } = global

  const section = nav
  if (!section?.spec && !title) {
    return <div className="mr-auto"></div>
  }
  const routeMap = section?.spec?.routeMap

  return (
    <div className="mr-auto">
      {(title || routeMap) && (
        <div className="fw4 f2 nav-max-view flex flex-column justify-end">
          {title && <label className="b f4 db mb1">{title}</label>}
          {routeMap ? (
            <GenerateTabs
              spec={section.spec}
              args={{ section, current: nav?.current, level: 'section', global }}
            />
          ) : (
            <div>&nbsp;</div>
          )}
        </div>
      )}
    </div>
  )
}

export function SubNav({ spec }) {
  const [global] = useContext(GlobalContext)
  const section = global.nav
  const [state] = useContext(spec.Context)
  const { routeMap } = spec

  return (
    <div className="bg-neutral bt b--transparent">
      <MaxViewPage className=" ">
        <div className="f5 accent mt3 mb2 fw6">{state.title || <>&nbsp;</>}</div>
        <div className=" w-100 flex gh4 mb4 f2">
          {routeMap ? (
            <GenerateTabs
              spec={spec}
              args={{
                section,
                current: state?.nav?.current,
                level: 'sub',
                global,
                state
              }}
              className="pv2"
            />
          ) : (
            <div className="pv2">&nbsp;</div>
          )}
        </div>
      </MaxViewPage>
    </div>
  )
}

function GenerateTabs({ spec, args, className = '' }) {
  const [state] = useContext(spec.Context)
  const { routeMap, order } = spec

  const shown = useMemo(
    () =>
      order.filter((tab) => {
        const { hide, visible } = routeMap[tab]
        return !hide && visible({ ...args, tab })
      }),
    [args, order, routeMap]
  )

  return (
    <div className="flex items-center gh3 navtabs">
      {shown.map((tab) => (
        <RoutedNavLink
          key={tab}
          spec={spec}
          state={state}
          tab={tab}
          args={args}
          className={className}
        />
      ))}
    </div>
  )
}

function RoutedNavLink({ spec, state, tab, args, className }) {
  const route = spec.routeMap[tab]
  args = { ...args, state, active: args.current === route.id, tab }

  if (route.separator) {
    return <div>|</div>
  } else {
    return (
      <NavLink
        to={route.path(args)}
        className={`button tab ${args.active ? 'neutral current' : 'clear'}`}
      >
        {route.label(args)}
      </NavLink>
    )
  }
}
